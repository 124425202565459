import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import AutoSuggest from "../AutoSuggest/AutoSuggest";
import { toast } from "react-toastify";
import moment from "moment";
import Popup from "reactjs-popup";
import { InfiniteCalendar } from "@demark-pro/react-booking-calendar";
import { RiCalendarEventFill } from "react-icons/ri";
import { MdLocationPin } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

const demoResult = [
  {
    id: "118361",
    name: "London",
    fullName: "London And Vicinity, England, United Kingdom",
    type: "MultiCity",
    state: "Greater London",
    country: "GB",
    coordinates: {
      lat: 51.507538,
      long: -0.127804,
    },
    referenceScore: 100000,
    isTermMatch: true,
  },
  {
    id: "472764",
    name: "New York",
    fullName: "New York, New York, United States of America",
    type: "MultiCity",
    state: "Queens County",
    country: "US",
    coordinates: {
      lat: 40.75668,
      long: -73.98647,
    },
    referenceScore: 100000,
    isTermMatch: true,
  },
  {
    id: "472793",
    name: "Los Angeles",
    fullName: "Los Angeles, California, United States of America",
    type: "MultiCity",
    state: "Los Angeles County",
    country: "US",
    coordinates: {
      lat: 34.05072,
      long: -118.25477,
    },
    referenceScore: 100000,
    isTermMatch: true,
  },
  {
    id: "9906",
    name: "Singapore",
    fullName: "Singapore, Singapore",
    type: "City",
    country: "SG",
    coordinates: {
      lat: 1.29027,
      long: 103.85201,
    },
    referenceScore: 102000,
    isTermMatch: true,
  },
  {
    id: "118470",
    name: "Paris",
    fullName: "Paris, France",
    type: "MultiCity",
    state: "Seine-et-Marne",
    country: "FR",
    coordinates: {
      lat: 48.86272,
      long: 2.34375,
    },
    referenceScore: 100000,
    isTermMatch: true,
  },
  {
    id: "472565",
    name: "Maui",
    fullName: "Maui, Hawaii, United States of America",
    type: "Region",
    state: "Hawaii",
    country: "US",
    coordinates: {
      lat: 20.80218,
      long: -156.34627,
    },
    referenceScore: 100000,
    isTermMatch: true,
  },
];

export default function HotelSearch({ color, icolor,isnavbaropen }) {
  const history = useHistory();
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [query, setQuery] = useState("");
  // const options = { month: "short", day: "numeric", year: "numeric" };
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [selectedResult, setSelectedResult] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const dropdownRef = useRef(null);
  const datePickerRef = useRef(null);
  const [formData, setFormData] = useState({
    occupancies: [
      {
        numOfAdults: 2,
        childAges: [],
      },
    ],
  });
  const [adults, setAdults] = useState(formData.occupancies[0].numOfAdults);
  const [numOfChildren, setNumOfChildren] = useState(0);
  const [isType, setIsType] = useState(null);
  const [boundaries, setBoundaries] = useState();
  // const [isLocationClicked, setIsLocationClicked] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleInputClick = () => {
    setShowCalendar(!showCalendar);
    document.body.style.overflow = "hidden";
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleClearInput = () => {
    setQuery("");
    setResults([]);
    setSelectedResult("");
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const addRoom = () => {
    if (formData.occupancies.length < 4) {
      setFormData({
        ...formData,
        occupancies: [
          ...formData.occupancies,
          {
            numOfAdults: 2,
            childAges: [],
          },
        ],
      });
      setAdults(adults + 2);
    }
  };
  const removeRoom = (index) => {
    const newOccupancies = [...formData.occupancies];
    newOccupancies.splice(index, 1);
    setFormData({
      ...formData,
      occupancies: newOccupancies,
    });
    setAdults(adults - formData.occupancies[index].numOfAdults);
    setNumOfChildren(
      numOfChildren - formData.occupancies[index].childAges.length
    );
  };
  const incrementAdults = (index) => {
    if (formData.occupancies[index].numOfAdults < 6) {
      const newOccupancies = [...formData.occupancies];
      newOccupancies[index].numOfAdults++;
      setFormData({
        ...formData,
        occupancies: newOccupancies,
      });
      setAdults(adults + 1);
    }
  };
  const decrementAdults = (index) => {
    if (formData.occupancies[index].numOfAdults > 1) {
      const newOccupancies = [...formData.occupancies];
      newOccupancies[index].numOfAdults--;
      setFormData({
        ...formData,
        occupancies: newOccupancies,
      });
      setAdults(adults - 1);
    }
  };
  const incrementChildren = (index) => {
    const newOccupancies = [...formData.occupancies];
    if (newOccupancies[index].childAges.length < 4) {
      newOccupancies[index].childAges.push("");
      setFormData({
        ...formData,
        occupancies: newOccupancies,
      });

      setNumOfChildren(numOfChildren + 1);
    }
  };
  const decrementChildren = (index) => {
    const newOccupancies = [...formData.occupancies];
    if (newOccupancies[index].childAges.length > 0) {
      newOccupancies[index].childAges.pop();
      setFormData({
        ...formData,
        occupancies: newOccupancies,
      });
      setNumOfChildren(numOfChildren - 1);
    }
  };
  const handleChildAgeChange = (roomIndex, childIndex, age) => {
    const newOccupancies = [...formData.occupancies];
    newOccupancies[roomIndex].childAges[childIndex] = parseInt(age);
    setFormData({
      ...formData,
      occupancies: newOccupancies,
    });
    if (age === "") {
      toast.error("Please select the age for the child.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  React.useEffect(() => {
    function handleResize() {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    }

    // Add event listener to window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize once to initialize screenSize state
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    if (start && end) {
      // Limit selected date range to 60 days
      const maxEndDate = new Date(start.getTime() + 30 * 24 * 60 * 60 * 1000);
      if (end > maxEndDate) {
        // end = maxEndDate;
        toast.error("Maximum date range is 30 days.");

        return;
      }
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(start);
      setEndDate(null);
    }
  };
  useEffect(() => {
    async function fetchResults() {
      if (query !== "") {
        try {
          const response = await axios.get(
            `https://autosuggest-v2.us.prod.zentrumhub.com/api/locations/locationcontent/autosuggest?term=${query}&countries=FR,ES,US,CN,IT,TR,MX,DE,TH,GB,JP,AT,GR,HK,MY,RU,PT,CA,NL,AR,KR,HR,CZ,MA,EG,ID,AE,PL,PH,AU,BE,SA,SE,CH,HU,IL,PT,SG,RO,ZA,FI,CL,IE,OM,BR,DK,NO,VN&strictFilterForCountries=false`
          );
          const data = response.data.locationSuggestions;

          const sortedData = data.sort((a, b) => {
            const aContainsHotel = query.toLowerCase().includes("hotel");

            if (aContainsHotel && a.type === "Hotel" && b.type !== "Hotel") {
              return -1; // a contains "hotel" should come before b
            } else if (
              !aContainsHotel &&
              a.type !== "Hotel" &&
              b.type === "Hotel"
            ) {
              return 1; // b contains "hotel" should come before a
            } else {
              const typeOrder = {
                MultiCity: 0,
                City: 1,
              };

              const aTypeOrder = typeOrder[a.type] || 2;
              const bTypeOrder = typeOrder[b.type] || 2;

              return aTypeOrder - bTypeOrder;
            }
          });

          setResults(sortedData);
          setError("");
        } catch (error) {
          setResults([]);
          setError("Error fetching results");
        }
      }
    }
    fetchResults();
  }, [query]);

  function handleInputChange(e) {
    setQuery(e.target.value);
    setResults([]);
    setSelectedResult(null);

    // Reset scroll position of the autosuggestion list
    const listElement = document.querySelector(".autosuggestionlist");
    if (listElement) {
      listElement.scrollTop = 0;
    }
  }
  const [show, setShow] = useState(false);

  function handleResultClick(result, close) {
    //console.log(result);
    setSelectedResult(result);
    setShow(false);
    setQuery("");
    setResults([]);
    if (typeof close === "function") {
      close();
    }
    // datePickerRef.current.setFocus();
  }
  const handleSearch = () => {
    if (startDate && endDate && selectedResult) {
      const formattedStartDate = moment(startDate).format(); // Form
      const formattedEndDate = moment(endDate).format(); // Form
      // Redirect to Hotel Listing page with search parameters
      localStorage.setItem(
        "searchParams",
        JSON.stringify({
          location: selectedResult,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          adults: adults,
          children: numOfChildren,
          occupancies: formData.occupancies,
          isType: isType,
          boundaries: boundaries,
        })
      );
      history.push(`/hotel-listing`);
    } else {
      toast.error("Please input your search criteria");
    }
    // window.reload();
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setResults([]);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const [selectedDates, setSelectedDates] = useState([]);

  const handleChange = (e) => {
    //console.log(e);
    setSelectedDates(e);
    const newStartDate = e[0];
    const newEndDate = e[1] ? e[1] : null;
    if (newEndDate && newStartDate) {
      const maxEndDate = new Date(
        newStartDate.getTime() + 30 * 24 * 60 * 60 * 1000
      );
      if (newEndDate > maxEndDate) {
        // end = maxEndDate;
        toast.error("Maximum date range is 30 days.");

        return;
      }
      setEndDate(new Date(newEndDate));
      setStartDate(newStartDate);
      //console.log(startDate, endDate);
      setShowCalendar(false);
      document.body.style.overflow = "auto";
    }
  };
  const inputRef = useRef(null);

  const openPopup = () => {
    setShow(true);
  };
  useEffect(() => {
    if (show) {
      inputRef.current.focus();
    }
  }, [show]);
  return (
    <>
      <div
        className={`bg-[${color}] relative z-10 drop-shadow-lg border border-gray-600 rounded-lg w-full items-center p-[6px]`}
      >
        <div className="flex justify-between items-center  px-3 py-3 flex-col lg:space-y-0 space-y-2 lg:flex-row">
          {screenSize.width > 480 ? (
            <div className="w-[100%]">
              <div className="w-full text-gray-600">
                <div
                  ref={wrapperRef}
                  className="flex w-full relative  items-center flex-row"
                >
                  <MdLocationPin className="h-[20px] w-[26px]" />

                  {/*  api auto autoComplete goes here */}
                  <div
                    className="search-location-input flex flex-col w-full"
                    ref={dropdownRef}
                  >
                    <label className="-mb-[4px] sticky ml-[11px] text-[13px]">
                      Going to
                    </label>

                    <input
                      className={`bg-[${icolor}] ml-[11px] text-[14px] lg:text-[12px] xl:text-[14px]  w-[99%] file:relative focus:outline-none focus:border-none flex-initial h-10 rounded-md font-base `}
                      placeholder="Enter a destination"
                      type="text"
                      value={selectedResult ? selectedResult.fullName : query}
                      onChange={handleInputChange}
                      onClick={() => {
                        openPopup();
                      }}
                    />
                  </div>

                  {show ? (
                    <div
                      className={`search-location-input bg-white shadowbox absolute rounded-[15px] -top-[27px] -left-[34px] w-[500px] xl:w-[600px] ${
                        show ? "popup-zoom " : "popupshow"
                      }`}
                      ref={dropdownRef}
                    >
                      <div className="input-container">
                        <input
                          ref={inputRef}
                          className={`bg-[${icolor}] px-2 w-full shadowbox rounded-tl-[15px] rounded-tr-[15px] relative z-50 file:relative focus:outline-none focus:border-none flex-initial h-[95px] font-base `}
                          placeholder="Where are you going?"
                          type="text"
                          value={selectedResult && selectedResult.fullName}
                          onChange={handleInputChange}
                          // onClick={() => }
                        />
                        {(selectedResult || query) && (
                          <span
                            className="cross-icon"
                            onClick={() => handleClearInput()}
                          >
                            ×
                          </span>
                        )}
                      </div>

                      {error && <p>{error}</p>}
                      {query !== null && query !== "" ? (
                        <>
                          <ul className="rounded-bl-[15px] rounded-br-[15px] overflow-y-auto h-[390px]  w-full overflow-x-auto relative z-50   bg-white  flex flex-col gap-2">
                            {results.map((result) => (
                              <li
                                className="hover:bg-[#1893F8] rounded-md cursor-pointer px-2 py-3 "
                                key={result.id}
                                onClick={() => {
                                  handleResultClick(result);
                                }}
                              >
                                {AutoSuggest(result.fullName, result.type)}
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <ul className="rounded-bl-[15px] rounded-br-[15px] overflow-y-auto h-[390px] w-full overflow-x-auto relative z-50    bg-white  flex flex-col gap-2">
                          {demoResult.map((result) => (
                            <li
                              className="hover:bg-[#1893F8] rounded-md cursor-pointer px-2 py-3 "
                              key={result.id}
                              onClick={() => handleResultClick(result)}
                            >
                              {AutoSuggest(result.fullName, result.type)}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Popup
              trigger={
                <div className="flex w-full items-center flex-row">
                  <MdLocationPin className="h-[20px] w-[26px]" />

                  {/*  api auto autoComplete goes here */}
                  <div
                    className="search-location-input w-full"
                    ref={dropdownRef}
                  >
                    <input
                      className={`bg-[${icolor}] ml-[11px] text-start w-[99%] file:relative focus:outline-none border-gray-600 flex-initial h-10 rounded-md font-base `}
                      placeholder="Enter a Location"
                      type="text"
                      value={selectedResult ? selectedResult.fullName : query}
                    />
                  </div>
                </div>
              }
              modal
            >
              {(close) => (
                <div
                  className={`w-screen h-screen bg-[${icolor}] flex flex-col`}
                >
                  <div className="flex justify-end  w-full">
                    <button onClick={() => close()}>
                      <AiOutlineClose />
                    </button>
                  </div>

                  <div className="w-full pt-3 text-gray-600">
                    <div className="flex w-full items-center flex-row">
                      {/*  api auto autoComplete goes here */}
                      <div
                        className="search-location-input w-full"
                        ref={dropdownRef}
                      >
                        <div className="input-container">
                          <input
                            className={`bg-[${icolor}] ml-[11px] border w-full file:relative focus:outline-none focus:border-none flex-initial h-10 rounded-md font-base `}
                            placeholder="Enter a Location"
                            type="text"
                            value={
                              selectedResult ? selectedResult.fullName : query
                            }
                            onChange={handleInputChange}
                          />
                          {(selectedResult || query) && (
                            <span
                              className="cross-icon"
                              onClick={() => handleClearInput()}
                            >
                              ×
                            </span>
                          )}
                        </div>
                        {error && <p>{error}</p>}
                        {query !== null && query !== "" ? (
                          <>
                            <ul className=" overflow-y-auto text-left items-start justify-between h-[500px] w-full md:w-[500px] overflow-x-auto lg:max-w-max z-50  rounded-md  bg-white  flex flex-col gap-2">
                              {results.map((result) => (
                                <li
                                  className="hover:bg-[#1893F8] w-full rounded-md cursor-pointer px-2 py-3 "
                                  key={result.id}
                                  onClick={() =>
                                    handleResultClick(result, close)
                                  }
                                >
                                  {AutoSuggest(result.fullName, result.type)}
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <ul className=" overflow-y-auto text-left items-start justify-between h-[500px] w-full md:w-[500px] overflow-x-auto lg:max-w-max z-50  rounded-md  bg-white  flex flex-col gap-2">
                            {demoResult.map((result) => (
                              <li
                                className="hover:bg-[#1893F8] w-full rounded-md cursor-pointer px-2 py-3 "
                                key={result.id}
                                onClick={() => handleResultClick(result, close)}
                              >
                                {AutoSuggest(result.fullName, result.type)}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          )}
          <div className="widthcalendar ml-0 lg:ml-[10px] flex items-center flex-row">
            <RiCalendarEventFill className="h-[20px] w-[26px]" />
            {screenSize.width > 480 ? (
              <div className="flex flex-col items-start">
                <label className="-mb-[4px] ml-[12px] sticky text-[13px]">
                  Dates
                </label>

                <DatePicker
                  ref={datePickerRef}
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  value={
                    endDate
                      ? startDate
                          .toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                          })
                          .replace(/-/g, "/") +
                        " - " +
                        endDate.toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                        })
                      : ""
                  }
                  selectsRange
                  withPortal
                  portalId="root-portal"
                  isClearable={true}
                  minDate={new Date()}
                  monthsShown={screenSize.width > 768 ? 2 : 1}
                  placeholderText="Check In - Check Out"
                  className={`-px-11 bg-[${icolor}] text-[14px] lg:text-[12px] xl:text-[14px]  flex-initial w-full h-10 rounded-md font-base px-3 py-2  focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent`}
                />
              </div>
            ) : (
              <>
                <input
                  type="text"
                  className={`-px-11 bg-[${icolor}] flex-initial w-full h-10 rounded-md font-base px-3 py-2  focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent`}
                  value={
                    endDate
                      ? startDate
                          .toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                          })
                          .replace(/-/g, "/") +
                        " - " +
                        endDate.toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                        })
                      : ""
                  }
                  onClick={handleInputClick}
                  readOnly
                  placeholder="Check In - Check Out"
                />
                {showCalendar && (
                  <div
                    className={`fullscreem zindex fixed inset-0 bg-[${icolor}] `}
                  >
                    <InfiniteCalendar
                      onChange={handleChange}
                      numOfMonths={400}
                      width={window.innerWidth}
                      height={window.innerHeight}
                      range={true}
                      selected={selectedDates}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="w-[100%] pl-[3px] lg:pl-0">
            <div className="relative mr-0">
              <span className="absolute inset-y-0 left-0 flex items-center ">
                <div className=" focus:shadow-outline">
                  {/* <div className="px-3 py-2.5 rounded-full bg-[#F1F5F5]">
                    <svg
                      width="15"
                      height="18"
                      viewBox="0 0 15 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="7.5013"
                        cy="4.50008"
                        r="3.33333"
                        stroke="#002248"
                        strokeWidth="1.5"
                      />
                      <ellipse
                        cx="7.5013"
                        cy="13.6666"
                        rx="5.83333"
                        ry="3.33333"
                        stroke="#002248"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div> */}
                  <FaUserAlt className="h-[20px]" />
                </div>
              </span>
              <div className="w-full flex flex-col">
                <label for="roomInput" className="-mb-[4px] hidden lg:block ml-[27px] text-[13px]">
                  Travelers
                </label>

                <input
                  type="text"
                  id="roomInput" 
                  onClick={() => setShowModal(true)}
                  value={`${formData.occupancies.length} Rooms , ${adults} Adults  , ${numOfChildren} Children`}
                  className={`py-3 px-0 ml-[10px] text-[14px] lg:text-[12px] xl:text-[14px]  pl-[22px] lg:pl-[7px] bg-[${icolor}]  xl:w-[300px] md:lg:w-auto sm:w-[auto] w-full text-sm rounded-md  ml-0 md:ml-5 focus:outline-none`}
                />
                {showModal && (
                  <div
                    className={`flex gap-3 border ml-[37px] ${
                      formData.occupancies.length > 2 ? "h-[425px]" : "h-auto"
                    }  overflow-y-scroll absolute shadow-2xl bg-white p-3 w-[240px] rounded-2xl flex-col`}
                  >
                    <span className="font-bold text-[17px]"> Travelers </span>
                    {formData.occupancies.map((occupancy, index) => (
                      <div key={index} className="flex flex-col gap-2">
                        <h2 className="font-bold text-[16px] justify-center text-center flex mb-3">
                          Room {index + 1}
                        </h2>
                        <div className="flex flex-row items-center justify-between w-full">
                          <span className="w-2/4">Adults</span>
                          <div className="w-2/4 flex justify-between">
                            <button
                              className={`border-[1px] rounded-full px-2 ${
                                occupancy.numOfAdults > 1
                                  ? "border-[#1893F8] text-[#1893F8]"
                                  : "text-[#838688] opacity-50 border-[#838688]"
                              }`}
                              onClick={() => decrementAdults(index)}
                            >
                              -
                            </button>
                            <span>{occupancy.numOfAdults}</span>
                            <button
                              className={`border-[1px] rounded-full px-2 ${
                                occupancy.numOfAdults < 6
                                  ? "border-[#1893F8] text-[#1893F8]"
                                  : "text-[#838688] opacity-50 border-[#838688]"
                              }`}
                              onClick={() => incrementAdults(index)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between w-full">
                          <span className="w-2/4">Children</span>
                          <div className="w-2/4 flex justify-between">
                            <button
                              className={` sticky border-[1px] rounded-full px-2 ${
                                occupancy.childAges.length > 0
                                  ? "border-[#1893F8] text-[#1893F8]"
                                  : "text-[#838688] opacity-50 border-[#838688]"
                              }`}
                              onClick={() => decrementChildren(index)}
                            >
                              -
                            </button>
                            <span>{occupancy.childAges.length}</span>
                            <button
                              className={`border-[1px] rounded-full px-2 ${
                                occupancy.childAges.length < 4
                                  ? "border-[#1893F8] text-[#1893F8]"
                                  : "text-[#838688] opacity-50 border-[#838688]"
                              }`}
                              onClick={() => incrementChildren(index)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        {occupancy.childAges.map((age, childIndex) => (
                          <div
                            key={childIndex}
                            className="flex flex-col space-y-3 items-center justify-between w-full"
                          >
                            <select
                              value={age}
                              onChange={(e) =>
                                handleChildAgeChange(
                                  index,
                                  childIndex,
                                  e.target.value
                                )
                              }
                              className=" border-[2px] font-bold rounded-full px-5 py-1"
                            >
                              <option value="">Age</option>
                              <option value="1">1 Year</option>
                              <option value="2">2 Years</option>
                              <option value="3">3 Years</option>
                              <option value="4">4 Years</option>
                              <option value="5">5 Years</option>
                              <option value="6">6 Years</option>
                              <option value="7">7 Years</option>
                              <option value="8">8 Years</option>
                              <option value="9">9 Years</option>
                              <option value="10">10 Years</option>
                              <option value="11">11 Years</option>
                              <option value="12">12 Years</option>
                              <option value="13">13 Years</option>
                              <option value="14">14 Years</option>
                              <option value="15">15 Years</option>
                              <option value="16">16 Years</option>
                              <option value="17">17 Years</option>
                            </select>
                          </div>
                        ))}
                        {index > 0 && (
                          <button
                            className="border-[#1893F8] border-[2px] rounded-md py-1"
                            onClick={() => removeRoom(index)}
                          >
                            Remove room
                          </button>
                        )}
                      </div>
                    ))}
                    {formData.occupancies.length < 4 && (
                      <div className="flex justify-end items-end  text-[#1893F8] font-[600] hover:underline">
                        <button
                          className="bg-[#71b9f5bd] hover:bg-[#1893F8] rounded-md text-white px-1 py-1"
                          onClick={addRoom}
                        >
                          Add room
                        </button>
                      </div>
                    )}
                    <button
                      onClick={() => {
                        // Check if any child ages are empty
                        const hasEmptyChildAges = formData.occupancies.some(
                          (occupancy) =>
                            occupancy.childAges.some((age) => age === "")
                        );

                        if (hasEmptyChildAges) {
                          toast.error(
                            "Please fill in the ages for all children.",
                            {
                              position: toast.POSITION.TOP_RIGHT,
                            }
                          );
                        } else {
                          setShowModal(false);
                        }
                      }}
                      className="bg-[#1893F8] rounded-full py-2 text-white "
                    >
                      Done
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-[100%] lg:w-[25%]">
            <button
              onClick={handleSearch}
              className="bg-[#1893F8] flex flex-row py-2 px-0 lg:px-2 lg:py-5 space-x-2 rounded-lg justify-center items-center w-full searchbar"
            >
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  cx="8.11732"
                  cy="8.6"
                  rx="7.28333"
                  ry="7.6"
                  stroke="white"
                  strokeWidth="1.5"
                />
                <path
                  d="M14.6328 15.3999L16.1661 16.9999"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>

              <span className="text-[#fff] lg:hidden block"> Search</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
