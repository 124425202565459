import React from 'react'
import { Route, Redirect } from 'react-router-dom';

export default function Protectedroutes({ component: Component, ...rest }) {
    const isAuthenticated = localStorage.getItem('isAuthenticated') || false;

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    // loginWithRedirect()
                    <Redirect to="/" />
                )
            }
        />
    )
}
