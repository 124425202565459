import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { CardElement ,useStripe, useElements } from "@stripe/react-stripe-js";
// import MembershipComplete from "../modals/membershipComplete";
import axios from "axios";
import { toast } from "react-toastify";

export default function CheckoutForm({close, dataRegister }) {
  const stripe = useStripe();
  const elements = useElements();


  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });
    //console.log("paymentIntent", paymentIntent);
    if (paymentIntent && paymentIntent.status === 'succeeded') {

      // setPaym// 
      axios
        .post(`${process.env.REACT_APP_AXIOS_URL}/api/auth/signup`, dataRegister)
        .then((result) => {
          toast.info("You have registered successfully.");
          close();
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
    if(error){
      setIsProcessing(false);
      setMessage("")
    }
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      toast(error.message)
    } else {
      setMessage("An unexpected error occured.");
      toast("An unexpected error occured.")
    }

    setIsProcessing(false);
    // setIsModalOpen(true);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button className="bg-[#1893F8] rounded-full font-bold py-2 w-full text-white mt-3" disabled={isProcessing || !stripe || !elements} id="submit">
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
      {/* {isModalOpen && <MembershipComplete close={() => setIsModalOpen(false)} /> } */}
    </>

  );
}
