import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { storeAccessToken } from "../../utils/indexedDB";
import navbarlogo from "../../assets/img/navbarlogo.png"

const SignInSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Firstname is required"),

  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Lastname is required"),

  phoneNumber: Yup.string().required("Phone number is required"),

  email: Yup.string().email().required("Email is required"),
});

const PasswordlessAuth = ({ close, props }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [error, setError] = useState("");
  const [isLogin, setIsLogin] = React.useState(true);
  const [isVerification, setIsVerification] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  // const { isAuthenticated  } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: "",
  });
  const [initialSignUpValues, setInitialSignUpValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });

  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
  };

  let checkUserAvalibilityCount = 0;

  const checkUserAvalibility = async (e) => {
    console.log("checkUserAvalibility" ,e );
    setFormData(e);
    if (isLogin === true) {
      setInitialValues(e);
    } else {
      setInitialSignUpValues(e);
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AXIOS_URL}/api/v1/auth/user/avalibility`,
        {
          email: e.email,
        }
      );
      const status = response.data.status;
      if (status === 0 && isLogin === true) {
        setError(
          `We couldn’t find a registered account with ${e.email}. Please register as a new member or check the email address again.`
        );
        setIsLoading(false);
      } else if (status === 1 && isLogin === false) {
        setError(
          `The provided email ${e.email} is already registered. Either login or register with a new email`
        );
        setIsLoading(false);
      } else {
        handleSendCode(e);
      }
    } catch (err) {
      if(checkUserAvalibilityCount < 3){
        checkUserAvalibilityCount++;
        checkUserAvalibility(e);
      }
      console.log(err);
    }
  };

  const handleSendCode = async (e) => {
    await axios
      .post("https://chekins.us.auth0.com/passwordless/start", {
        client_id: "4ou6l0MrzVzN30Opj9j67iROl0Z4tsSY",
        connection: "email",
        email: e.email,
        send: "code",
      })
      .then((response) => {
        // console.log('Verification code sent:', response.data);
        // Handle successful email sending (e.g., show success message)
        setIsVerification(true);
        setError("");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error sending verification code:", error);
        setError("Error sending verification code. Please try again.");
        setIsLoading(false);
      });
  };

  const handleVerifyCode = async () => {
    console.log(formData);
    setIsLoading(true);
    await axios
      .post("https://chekins.us.auth0.com/oauth/token", {
        grant_type: "http://auth0.com/oauth/grant-type/passwordless/otp",
        client_id: "4ou6l0MrzVzN30Opj9j67iROl0Z4tsSY",
        client_secret:
          "FwGEsl0fHsGxjVkbPYSJZ_0dbCaL3KvKiAWiZNhGg90q7YDQpm_montSiC98mvn1",
        username: formData.email,
        otp: verificationCode,
        realm: "email",
        audience: "chekinsbackend",
        scope: "openid profile email",
      })
      .then((response) => {
        // console.log("Access token:", response.data);
        localStorage.setItem("email", formData.email);
        localStorage.setItem("isAuthenticated", true);
        setAccessToken(response.data.access_token);
        storeAccessToken({
          accessToken: response.data.access_token,
          data: response.data,
        });
        if (
          isLogin === false &&
          formData?.firstName !== "" &&
          formData?.lastName !== "" &&
          formData?.phoneNumber !== ""
        ) {
          newUserCreation(response.data.access_token);
        }
        setError("");
        // Handle successful authentication (e.g., redirect to dashboard)
        setIsLoading(false);
        close();
        if (isLogin === false) {
          toast.info("Congratulations on successfully registering with Checkins.ai! 🎉");
        } else {
          toast.info("Welcome back to Checkins.ai! 🎉");
        }
        if (typeof props === "function" || props !== undefined) {
          props(true);
        }
      })
      .catch((error) => {
        console.log("Error verifying code:", error);
        setError(error.response.data.error_description);
        setIsLoading(false);
      });
  };

  const newUserCreation = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AXIOS_URL}/api/v1/auth/user/avalibility/new`,
        formData,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleSwitchForm = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div className="fixed higherIndexFooter h-screen w-screen bg-gray-800 bg-opacity-50 pb-4 inset-0 sm:flex sm:items-center sm:justify-center">
      <div
        className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all "
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      ></div>
      <div className="bg-[#fff]  shadow-xl  w-full lg:h-auto lg:w-[450px] rounded-xl ">
        <div className="flex flex-end pr-2 justify-end items-end">
          <div
            className="bg-[#F5F5F6] cursor-pointer border-none rounded-full px-2 py-2 mt-1 items-center justify-center "
            onClick={() => {
              close();
            }}
          >
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3327 1.1665L1.66602 12.8332"
                stroke="#282C3F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.66602 1.1665L13.3327 12.8332"
                stroke="#282C3F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="pt-3.5 pb-9 flex flex-col justify-center px-9 space-y-4">
          <div className="space-y-2">
            <div className="flex justify-center items-center">
              {/* <span className="text-[#1B1B1B] text-[23px] font-bold">
                  Login Or Register
                </span> */}
              <img
                src={navbarlogo}
                alt="Logo"
                className="h-[52px]"
              />
            </div>
          </div>
          {isVerification ? (
            <>
              <span className="text-[#1B1B1B] text-[20px]  text-center">
                Welcome
              </span>
              <span className="text-[14px]  text-center">
                A verification code has been sent to {formData.email}.
              </span>
              {error !== "" && (
                <>
                  <span className="text-[#af3232] text-[12px] text-center">
                    {error} Please try again.
                    {/* <button
                      className="text-[#1893F8]"
                      onClick={() => {
                        setIsVerification(!isVerification);
                        setError("");
                      }}
                    >
                      Go Back
                    </button> */}
                  </span>
                </>
              )}
              <input
                type="text"
                value={verificationCode}
                onChange={handleVerificationCodeChange}
                placeholder="Verification Code"
                className={`relative block w-full appearance-none  rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm`}
              />
              <button
                className={`bg-[#1893F8] rounded-md font-bold py-3 w-full text-white `}
                onClick={handleVerifyCode}
                disabled={isLoading} // Disable the button while loading
              >
                {isLoading ? "Verifying..." : "Verify Code"}
              </button>
            </>
          ) : isLogin ? (
            <>
              <span className="text-[#1B1B1B] text-[20px]  text-center">
                Welcome
              </span>
              <span className="text-[14px]  text-center">
                Please use your registered email to login
              </span>
              {error !== "" && (
                <span className="text-[#af3232] text-[12px] text-center">
                  {error}
                </span>
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={SignInSchema}
                onSubmit={checkUserAvalibility}
              >
                {(formik) => {
                  const { errors, touched, isValid, dirty } = formik;
                  return (
                    <Form>
                      <div className="space-y-3">
                        <div>
                          <span className="text-[15px]">Email ID</span>
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            placeholder="jasoncooper@Checkins.ai"
                            className={`relative block w-full appearance-none  rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${
                              errors.email && touched.email
                                ? "border-red-500"
                                : null
                            }`}
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            className="text-red-500"
                          />
                        </div>

                        <button
                          className={`bg-[#1893F8] rounded-md font-bold py-3 w-full text-white ${
                            !(dirty && isValid) ? "cursor-not-allowed" : null
                          }`}
                          // disabled={!(dirty && isValid)}
                          type="submit"
                        >
                          {isLoading ? "Sending the code..." : "Continue"}
                        </button>
                        <div className=" flex gap-2">
                          <span className="">Don't have an account?</span>
                          <button
                            type="handlerForm"
                            onClick={handleSwitchForm}
                            className="hover:underline text-[#1893F8]"
                          >
                            Sign up
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </>
          ) : (
            <>
              <span className="text-[#1B1B1B] text-[20px]  text-center">
                Create Your Account
              </span>
              <span className="text-[14px]  text-center">
                Please fill in the form below to register
              </span>
              {error !== "" && (
                <span className="text-[#af3232] text-[12px] text-center">
                  {error}
                </span>
              )}

              {/* <ResetPassword /> */}
              <Formik
                initialValues={initialSignUpValues}
                validationSchema={SignUpSchema}
                onSubmit={checkUserAvalibility}
              >
                {(formik) => {
                  const { errors, touched, isValid, dirty } = formik;
                  return (
                    <Form>
                      <div className="space-y-3">
                        <div>
                          <span className="text-[15px]">First name</span>
                          <Field
                            id="firstName"
                            name="firstName"
                            type="firstName"
                            autoComplete="firstName"
                            required
                            className={`relative block w-full appearance-none  rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${
                              errors.firstName && touched.firstName
                                ? "border-red-500"
                                : ""
                            }`}
                            placeholder="Jason"
                          />
                          <ErrorMessage
                            name="firstName"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
                        <div>
                          <span className="text-[15px]">Last name</span>
                          <Field
                            id="lastName"
                            name="lastName"
                            type="lastName"
                            autoComplete="lastName"
                            required
                            className={`relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${
                              errors.lastName && touched.lastName
                                ? "border-red-500"
                                : ""
                            }`}
                            placeholder="Cooper"
                          />
                          <ErrorMessage
                            name="lastName"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
                        <div>
                          <span className="text-[15px]">Phone Number</span>
                          <Field
                            id="phone-number"
                            name="phoneNumber"
                            type="phoneNumber"
                            autoComplete="phoneNumber"
                            required
                            className={`relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${
                              errors.phoneNumber && touched.phoneNumber
                                ? "border-red-500"
                                : ""
                            }`}
                            placeholder="+1-100-852-685"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
                        <div>
                          <span className="text-[15px]">Email ID</span>
                          <Field
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            placeholder="Jasoncooper@Checkins.ai"
                            required
                            className={`relative block w-full appearance-none  rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${
                              errors.email && touched.email
                                ? "border-red-500"
                                : null
                            }`}
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            className="text-red-500"
                          />
                        </div>

                        <button
                          className={`bg-[#1893F8] rounded-md font-bold py-2 w-full text-white ${
                            !(dirty && isValid) ? "cursor-not-allowed" : null
                          }`}
                          // disabled={!(dirty && isValid)}
                          // type="button"
                        >
                          {isLoading ? "Sending the code..." : "Continue"}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <div className=" flex gap-2">
                <span className="">Already have an account?</span>
                <button
                  type="handlerForm"
                  onClick={handleSwitchForm}
                  className="hover:underline text-[#1893F8]"
                >
                  Log in
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordlessAuth;
