import React, { useEffect, useState } from "react";
import axios from "axios";
import Payment from "../Payment/Payment";
import Popup from 'reactjs-popup';
import { toast } from "react-toastify";

export default function MemeberShipPurchase({ close, dataRegister }) {
  // const [data, setData] = useState([]);
  const [paymentIs, setPaymentIs] = useState(false);
  const [Amount, setAmount] = useState(199);
  // const [plan_Name, setPlan_Name] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [number, setNumber] = useState(0);
  const [isHidden, setIsHidden] = useState(false);

  // useEffect(() => {
  //   if (dataRegister.promoCode === "FRIENDS100") {
  //     setAmount(0)
  //   }

  // }, [])
  const additionalData = {
    membershipType: "PAID MEMBERSHIP",
    membershipAmount: Amount,
  };

  const updatedDataRegister = {
    ...dataRegister,
    ...additionalData
  };

  const onHandler = () => {
    // setAmount(0)
    if (promoCode === "FRIENDS100") {
      setAmount(0)
      setNumber(1)
    } else if (promoCode !== "") {
      toast.error("Invalid Promo Code")
    }
    setIsHidden(false)
  }

  const register = async () => {
    // console.log("updatedDataRegister", updatedDataRegister);
    await axios
      .post(`${process.env.REACT_APP_AXIOS_URL}/api/auth/signup`, updatedDataRegister)
      .then((result) => {
        toast.info("You have registered successfully.");
        close();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  const RemoveDiscount = () => {
    setAmount(199);
    setPromoCode("");
    setNumber(0);
  }

  return (
    <div className="fixed z-50 bottom-0 inset-x-0 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
      <div
        className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all "
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      ></div>
      <div className="bg-[#fff]  shadow-xl  w-full lg:h-auto lg:w-[600px] rounded-xl text-[#5C6A7A]">
        <div className="py-2 pt-7 justify-around px-5 space-y-4">
          <div className="space-y-2">
            <div className="flex flex-row justify-between">
              <span className="text-[#1B1B1B] text-[15px] lg:text-[23px] font-bold">
                Fill A Form For Membership Purchase
              </span>
              <div
                className="bg-[#F5F5F6] cursor-pointer border-none mx-auto px-3 lg:px-2 lg:py-2 py-3 lg:mt-1 rounded-full  items-center justify-center "
                onClick={() => {
                  close();
                }}
              >
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3327 1.1665L1.66602 12.8332"
                    stroke="#282C3F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.66602 1.1665L13.3327 12.8332"
                    stroke="#282C3F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="h-[1px] bg-gray-300 font-[600] "></div>
          </div>
          <div>
            <span className="text-[15px]">Amount</span>
            <select
              disabled
              className="select-dropdown-arrow w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
              name=""
              id=""
            >
              <option value={Amount}>{Amount === 0 ? 'Free' : `$ ${Amount}`} </option>
            </select>
          </div>
          {number === 0 && (<>
            <button onClick={() => setIsHidden(!isHidden)} className="cursor-pointer text-[13px] border-none hover:underline hover:text-[#1893F8] mx-auto px-3 lg:px-2 lg:py-2 py-3 lg:mt-1 rounded-full  items-center justify-center ">{isHidden ? "hide" : "Have a promo code?"}</button>
            {/* <span className="text-[15px] text-left" >Promo Code</span> */}

            {isHidden && (

              <div className="flex flex-row gap-2">

                <div className="relative">

                  <input

                    className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
                    type="text"
                    value={promoCode}
                    placeholder="Enter Promo Code"
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                    }}
                  />
                  {promoCode !== '' && (
                    <span onClick={() => setPromoCode('')} className="absolute cursor-pointer inset-y-0 right-1.5 flex items-center pl-2">
                      <svg
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        className="ml-2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.3327 1.1665L1.66602 12.8332"
                          stroke="#282C3F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.66602 1.1665L13.3327 12.8332"
                          stroke="#282C3F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  )}
                </div>

                <button className="bg-[#1893F8]  cursor-pointer text-white border-none px-3 lg:py-[3px] py-[2px] rounded-md   items-center justify-center " onClick={() => {
                  onHandler()
                }}>Apply</button>

              </div>
            )}
          </>)}

          {number === 1 && (
            <div className="flex flex-col justify-center items-center">
              <div className="text-[15px] flex flex-row gap-2">
                <span>You have activated 100% discount</span>
                <button onClick={() => RemoveDiscount()} className="text-[13px]">Remove discount</button>
              </div>
              <button
                className="bg-[#1893F8] py-2 text-white cursor-pointer border-none w-full rounded-full  items-center justify-center "
                onClick={() => {
                  if (number === 1) {
                    register();
                  } else if (number === 0) {
                    setPaymentIs(true);
                  }
                }}
              >
                {number === 1 ? 'Register' : 'Pay Now'}
              </button>
            </div>
          )}

          {number === 0 && <Payment close={close} Amount={Amount} dataRegister={updatedDataRegister} />}
          {/* // <Payment Amount={Amount}/> */}
        </div>
      </div>
    </div>
  );
}
