import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import "./i18n";
import "./i18nextInit";
import "./styles/global.css";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { basicDetails } from "./services/reducers/reducers";
import { ChakraProvider } from '@chakra-ui/react'
import { PostHogProvider} from 'posthog-js/react'

const store = createStore(basicDetails);
const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
    <ChakraProvider >
      <App />
    </ChakraProvider>
    </PostHogProvider>

  </Provider>
);
