import React, { useEffect, useState } from "react";
// import gift from "../../assets/img/gift.png";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import MemeberShipPurchase from "./MemeberShipPurchase";

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Firstname is required"),

  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Lastname is required"),

  phoneNumber: Yup.string().required("Phone number is required"),
  // .matches(
  //   /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g,
  //   "Invalid phone number"
  // )
  email: Yup.string().email().required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum"),

  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export default function Register({ close }) {
  const [refferal, setRefferal] = useState(localStorage.getItem("storedValue") ? localStorage.getItem("storedValue") : null);

  useEffect(() => {
    localStorage.getItem("storedValue") && setRefferal(localStorage.getItem("storedValue"));
  }, []);

  const initialValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    promoCode: "",
    refferalCode: refferal,
    Membership: false,
  };




  const [dataRegister, setDataRegister] = React.useState({});

  const [isRegister, setIsRegister] = React.useState(true);
  const handleSubmit = (e) => {
    setIsRegister(false);
    setDataRegister(e);
  };
  return (
    <>
      <div className="fixed higherIndexFooter h-screen w-screen bg-gray-700 bg-opacity-50 pb-4 inset-0 sm:flex sm:items-center sm:justify-center">
        <div
          className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        ></div>
        {isRegister ? (
          <div className="bg-[#fff] register md:h-auto w-full md:w-[600px] rounded-xl text-[#5C6A7A]">
            <div className="py-2 pt-4 px-5 space-y-4">
              <div className="space-y-2">
                <div className="flex flex-row items-center justify-between">
                  <span className="text-[#1B1B1B] text-[15px] lg:text-[23px] font-bold">
                    Fill A Form For Membership Purchase
                  </span>
                  <div
                    className="bg-[#F5F5F6] cursor-pointer border-none rounded-full mx-auto px-3 lg:px-2 lg:py-2 py-3 lg:mt-1 items-center justify-center "
                    onClick={() => {
                      close();
                    }}
                  >
                    <svg
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3327 1.1665L1.66602 12.8332"
                        stroke="#282C3F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.66602 1.1665L13.3327 12.8332"
                        stroke="#282C3F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="h-[1px] bg-gray-300 font-[600] "></div>
              </div>

              <Formik
                initialValues={initialValues}
                validationSchema={SignUpSchema}
                onSubmit={handleSubmit}
              >
                {(formik) => {
                  const { errors, touched, isValid, dirty } = formik;
                  return (
                    <Form>
                      <div className="flex flex-col gap-2 ">
                        {/* <div className="flex items-center space-x-2 flex-row">
                            <Field
                              type="checkbox"
                              className="h-5 w-5"
                              name="Membership"
                            />
                            <img src={gift} alt="" className="h-5 w-5" />
                            <span>I am buying for someone else</span>
                          </div> */}
                        <div>
                          <span className="text-[15px]">First name</span>
                          <Field
                            id="firstName"
                            name="firstName"
                            type="firstName"
                            autoComplete="firstName"
                            required
                            className={`relative block w-full appearance-none  rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${errors.firstName && touched.firstName
                                ? "border-red-500"
                                : ""
                              }`}
                            placeholder="Enter First Name"
                          />
                          <ErrorMessage
                            name="firstName"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
                        <div>
                          <span className="text-[15px]">Last name</span>
                          <Field
                            id="lastName"
                            name="lastName"
                            type="lastName"
                            autoComplete="lastName"
                            required
                            className={`relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${errors.lastName && touched.lastName
                                ? "border-red-500"
                                : ""
                              }`}
                            placeholder=" Enter Last Name"
                          />
                          <ErrorMessage
                            name="lastName"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
                        <div>
                          <span className="text-[15px]">Email</span>
                          <Field
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className={`relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${errors.email && touched.email
                                ? "border-red-500"
                                : ""
                              }`}
                            placeholder="Enter Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
                        <div>
                          <span className="text-[15px]">Phone Number</span>
                          <Field
                            id="phone-number"
                            name="phoneNumber"
                            type="phoneNumber"
                            autoComplete="phoneNumber"
                            required
                            className={`relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${errors.phoneNumber && touched.phoneNumber
                                ? "border-red-500"
                                : ""
                              }`}
                            placeholder="Enter phone number"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
                        <div>
                          <span className="text-[15px]">Password</span>
                          <Field
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="password"
                            required
                            className={`relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${errors.password && touched.password
                                ? "border-red-500"
                                : ""
                              } `}
                            placeholder="Password"
                          />
                          <ErrorMessage
                            name="password"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
                        <div className="mb-[26px]">
                          <span className="text-[15px]">
                            Confirm Password
                          </span>
                          <Field
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            autoComplete="confirmPassword"
                            required
                            className={`relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${errors.confirmPassword &&
                                touched.confirmPassword
                                ? "border-red-500"
                                : ""
                              }`}
                            placeholder="Confirm Password"
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
                        {/* <div className="">
                            <span className="text-[15px]">Promo code</span>
                            <Field
                              id="promoCode"
                              name="promoCode"
                              type="promoCode"
                              autoComplete="promoCode"
                              className=" block appearance-none w-full rounded-md border border-gray-300 px-3 lg:px-3  py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm"
                              placeholder="Enter Promo code"
                            />
                          </div> */}
                        {/* <div className=" ">
                            <span className="text-[15px]">Referral Code</span>
                            <Field
                              id="referralCode"
                              name="referralCode"
                              type="referralCode"
                              autoComplete="referralCode"
                              className="relative block  w-full  appearance-none rounded-md border border-gray-300 px-3 lg:px-3  py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm"
                              placeholder="Enter Referral Code"
                            />
                          </div> */}
                        <button
                          className={`bg-[#1893F8]  mb-[15px] rounded-full font-bold py-2 w-full text-white ${!(dirty && isValid) ? "cursor-not-allowed" : null
                            }`}
                          disabled={!(dirty && isValid)}
                        // onClick={handleSubmit}
                        >
                          Continue
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

            </div>
          </div>
        ) : (
          <MemeberShipPurchase close={close} dataRegister={dataRegister} />
        )}
      </div>
    </>
  );
}
