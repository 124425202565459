import { useEffect, useState } from "react";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../checkoutForm/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

function Payment({close , Amount , dataRegister}) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const Currency =  "USD";

  useEffect(() => {
    functionPayment();
  }, []);

  let number = 0;
  const functionPayment = () => {
    fetch(`${process.env.REACT_APP_AXIOS_URL}/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    }).catch((error) => {
      if (number < 5) {
        number++;
        functionPayment();
      }
    });
  };

  useEffect(() => {
    secondFunction();
  }, []);

  let secondnumber = 0;
  const secondFunction = () => {
    axios
    .post(`${process.env.REACT_APP_AXIOS_URL}/create-payment-intent`, {
      price: Amount * 100,
      currency: Currency,

    })
    .then(async (result) => {
      var { clientSecret } = await result.data;
      setClientSecret(clientSecret);
    }).catch((error) => {
      if (secondnumber < 5) {
        secondnumber++;
        secondFunction();
      }
    });
  }

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm close={close} dataRegister = {dataRegister} />
        </Elements>
      )}
    </>
  );
}

export default Payment;
