import React, { useEffect, useState } from "react";
import Register from "../../../components/modals/register";
import homelogo from "../../../assets/frontimage.png";
// import AIASSISTANT from '../../../assets/img/dashboard/AIASSISTANT2.gif'
import NavBar from "../../../layouts/navbar/NavBar";
import { useTranslation } from "react-i18next";
import HotelSearch from "../../../components/HotelSearch/HotelSearch";
import { useHistory } from 'react-router-dom';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function TopSection() {

  const [show , setShow] = useState(false);
  // const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  // React.useEffect(() => {
  //   function handleResize() {
  //     setScreenSize({ width: window.innerWidth, height: window.innerHeight });
  //   }

  //   // Add event listener to window resize
  //   window.addEventListener('resize', handleResize);

  //   // Call handleResize once to initialize screenSize state
  //   handleResize();

  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);


  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  // const [showContent, setShowContent] = useState(false);
  // const handleClick = () => {
  //   setShowContent(!showContent);
  // };

  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 5000);

  },[])

  const color = '#fff'
  const icolor = '#fff'
  const history = useHistory();
  const handleSearch = ({ location, startDate, endDate, rooms, adults, children, childrenAges }) => {
    const searchParams = new URLSearchParams();
    searchParams.append('location', location);
    searchParams.append('start', startDate);
    searchParams.append('end', endDate);
    searchParams.append('rooms', rooms);
    searchParams.append('adults', adults);
    searchParams.append('children', children);
    searchParams.append('childrenAges', childrenAges);

    // Redirect to Hotel Listing page with search parameters
    history.push(`/hotels-listing`);
  }
  return (
    <>
      <NavBar />
      <section id="hero" className=" font-Montserrat ">
        <div className="container bg-[#37B7FE] rounded-lg lg:w-10/12 flex flex-col mx-auto  ">
          <div className="flex space-y-0 items-center justify-between md:space-y-0 md:flex-row">
            <div className="flex lg:px-6 px-0 flex-col mb-3 lg:mb-[44px] space-y-6 w-full ">
              <h1 className="max-w-full  font-header flex flex-col text-[33px] 2xl:text-[42px] font-[900] text-white text-center  md:text-left">
                {t("Hotel Bookings Redefined")}
                {/* <span> {t("hotel_bookings")}</span> */}
              </h1>
              <h2 className="text-[15px] w-full xl:w-[80%] 2xl:text-[18px] text-start font-[400] flex flex-col text-white md:text-left">
                {t(
                  "We are a travel tech company, offering up to 40% savings on hotel bookings, by providing you direct access to whole sale prices."
                  // "Embrace the extraordinary – You just found the best way to book hotels. We stand apart from the rest, as we bring you the finest vacation properties worldwide at prices that defy expectations."
                  // "just_pay_a_one_time_annual_membership_fees_and_book_as_many_times_as_you_like_simple"
                )}
                <h3> Compare our prices with Expedia or Booking.com and see for yourself!</h3>

              </h2>
              {/* <p className="text-[15px] 2xl:text-[18px] text-center font-[400] text-[#5C6A7A] md:text-left">
              {t(
                "Explore our unrivaled portfolio, conduct your price comparisons, and prepare to be captivated by the unmatched value and excellent savings we offer.Experience luxury redefined!"
                // "just_pay_a_one_time_annual_membership_fees_and_book_as_many_times_as_you_like_simple"
              )}
            </p> */}
              {/* <div className="flex items-center 2xl:flex-row flex-col gap-2 justify-center md:justify-start">
              <button
                onClick={() => setIsOpen(true)}
                // href="/"
                disabled
                className="p-3 text-[15px] px-6 pt-2 text-white drop-shadow-lg bg-veryLightWhitishBlue rounded-full baseline"
              >
                {t("FREE Membership Worth $199 with your first booking")}
              </button>
              <span className="font-bold"></span>
            </div> */}
            </div>
            <div className="lg:flex w-1/3 flex-end justify-end items-end hidden">
          <img src={homelogo} alt="" className="w-[100%]" />
        </div>
          </div>
          <HotelSearch onSearch={handleSearch} color={color} icolor={icolor} />
        </div>
        {isOpen && <Register close={close} />}
      </section>
    </>
  );
};