import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from "../layouts/footer/Footer";
import Protectedroutes from "./Protected.routes";
import HomePage from '../pages/HomePage/HomePage';
const HotelListing = lazy(() => import('../pages/HotelListing/HotelListing'));
const HotelDetails = lazy(() => import('../pages/hotelDetails'));
const ReviewBooking = lazy(() => import('../pages/ReviewBooking/ReviewBooking'));
const ClientDashboard = lazy(() => import('../pages/ClientDashboard/ClientDashboard'));
const ResetPassword = lazy(() => import('../pages/ResetPassword/ResetPassword'));
const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'));
const EmailVerify = lazy(() => import('../pages/EmailVerify/EmailVerify'));
const MembershipComplete = lazy(() => import('../pages/MemeberShipComplete/membershipComplete'));
const hotelDetailsPageDirect = lazy(() => import('../pages/hotelDetailsPageDirect'));
const HotelDetailsForGoogle = lazy(() => import('../pages/hotelDetailsForGoogle'));
const Blog = lazy(() => import('../pages/Blog/Blog'));
const MyTrip = lazy(() => import('../pages/MyTrip/MyTrip'));
const MemberShip = lazy(() => import('../pages/MemberShip/MemberShip'));
const AboutUs = lazy(() => import('../pages/AboutUs/AboutUs'));
const Reviews = lazy(() => import('../pages/Reviews/Reviews'));
const Terms = lazy(() => import('../pages/Terms/Terms'));
const Careers = lazy(() => import('../pages/Careers/Careers'));
const HelpCenter = lazy(() => import('../pages/HelpCenter/HelpCenter'));
const PriceGurantee = lazy(() => import('../pages/PriceGurantee/PriceGurantee'));
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions/TermsAndConditions'));
const BlogDetails = lazy(() => import('../pages/BlogDetails/BlogDetails'));
const ContactUs = lazy(() => import('../pages/ContactUs/ContactUs'));

const ProjectRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/hotel-listing" exact component={HotelListing} />
          <Route path="/hotel-details-page/:id/:token" exact component={HotelDetails} />
          <Route path="/review-booking/:id/:roomtoken" exact component={ReviewBooking} />
          <Protectedroutes path="/client-dashboard" component={ClientDashboard} />
          <Route path="/reset-password/:id/:resetString" exact component={ResetPassword} />
          <Route path="/verify/:id/:token" component={EmailVerify} />
          <Route path="/membership-complete" component={MembershipComplete} />
          <Route path="/hotel-details-page" exact component={hotelDetailsPageDirect} />
          <Route path="/hotel-details/:type" exact component={HotelDetailsForGoogle} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/Blogs" exact component={Blog} />
          <Route path="/PriceGuarantee" exact component={PriceGurantee} />
          <Route path="/membership" exact component={MemberShip} />
          <Route path="/AboutUs" exact component={AboutUs} />
          <Route path="/Reviews" exact component={Reviews} />
          <Route path="/MyTrip" exact component={MyTrip} />
          <Route path="/Careers" exact component={Careers} />
          <Route path="/HelpCenter" exact component={HelpCenter} />
          <Route path="/TermsAndConditions" exact component={TermsAndConditions} />
          <Route path="/FAQ" exact component={Terms} />
          <Route path="/blog-details/:id" exact component={BlogDetails} />
          <Route path="*" component={PageNotFound} />
        </Switch>
        <Footer />

      </Suspense>
    </Router>
  );
};

export default ProjectRoutes;
