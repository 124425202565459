import React from 'react'
import { FaHotel, FaCity } from "react-icons/fa";
import { GiModernCity } from "react-icons/gi";
import { IoLocationSharp } from "react-icons/io5";
import { BsHousesFill } from "react-icons/bs";
import { WiTrain } from "react-icons/wi";
import { TiPointOfInterest } from "react-icons/ti";
import { MdLocalAirport } from "react-icons/md";

export default function AutoSuggest(name, type) {
    const nameParts = name.split(',').map(part => part.trim());
    const formattedName = (
        <>
            <span className="font-bold flex-row gap-2 items-center">
                {nameParts[0]}
            </span>
            {nameParts.slice(1,10).map((part, index) => (
                <span key={index} className={`text-sm ${index === 1 ? '  break-after-comma' : ''}`}>
                    {index === 0 ?`, ${part}` : part}
                </span>
            ))}
        </>
    );
    switch (type) {
        case 'City':
            return (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex  flex-row gap-2 items-center">
                        <FaCity /> <span className="break-after-comma">{formattedName}</span>
                    </div>
                    <span className="text-xs px-1 py-1 border-gray-400 border-[1px]">
                        {type}
                    </span>
                </div>
            );
        case 'Hotel':
            return (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex  flex-row gap-2 items-center">
                        <FaHotel /> <span className="break-after-comma">{formattedName}</span>
                    </div>
                    <span className="text-xs px-1 py-1 border-gray-400 border-[1px]">
                        {type}
                    </span>
                </div>
            );
        case 'State':
            return (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex  flex-row gap-2 items-center">
                        <FaCity /> <span className="break-after-comma">{formattedName}</span>
                    </div>
                    <span className="text-xs px-1 py-1 border-gray-400 border-[1px]">
                        {type}
                    </span>
                </div>
            );
        case 'Neighborhood':
            return (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex  flex-row gap-2 items-center">
                        <BsHousesFill /> <span className="break-after-comma">{formattedName}</span>
                    </div>
                    <span className="text-xs px-1 py-1 border-gray-400 border-[1px]">
                        {type}
                    </span>
                </div>
            );
        case 'MultiCity':
            return (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex  flex-row gap-2 items-center">
                        <GiModernCity /> <span className="break-after-comma">{formattedName}</span>
                    </div>
                    <span className="text-xs px-1 py-1 border-gray-400 border-[1px]">
                        Multi City
                    </span>
                </div>
            );
        case 'TrainStation':
            return (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex  flex-row gap-2 items-center">
                        <WiTrain /> <span className="break-after-comma">{formattedName}</span>
                    </div>
                    <span className="text-xs px-1 py-1 border-gray-400 border-[1px]">
                        Train Station
                    </span>
                </div>
            );
        case 'Airport':
            return (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex  flex-row gap-2 items-center">
                        <MdLocalAirport /> <span className="break-after-comma">{formattedName}</span>
                    </div>
                    <span className="text-xs px-1 py-1 border-gray-400 border-[1px]">
                        {type}
                    </span>
                </div>
            );
        case 'PointOfInterest':
            return (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex  flex-row gap-2 items-center">
                        <TiPointOfInterest /> <span className="break-after-comma">{formattedName}</span>
                    </div>
                    <span className="text-xs px-1 py-1 border-gray-400 border-[1px]">
                        POI
                    </span>
                </div>
            );
        default:
            return (
                <div className="flex flex-row justify-between items-center">
                    <div className="flex  flex-row gap-2 items-center">
                        <IoLocationSharp /> <span className="break-after-comma">{formattedName}</span>
                    </div>
                    <span className="text-xs px-1 py-1 border-gray-400 border-[1px]">
                        {type}
                    </span>
                </div>
            );
    }
}
