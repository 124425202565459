import React, { useEffect, useState } from "react";
import ProjectRoutes from "./routes/routes";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";
import { Provider } from "react-wrap-balancer";
// import { hotjar } from 'react-hotjar'
import { Analytics } from '@vercel/analytics/react';
function App() {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  // const siteId = 5094206;

  // const hotjarVersion = 6;

  useEffect(() => {
    setShowScrollToTop(true);

    // Initialize Hotjar with your Site ID
    // hotjar.initialize(siteId, hotjarVersion);
  }, []);

  return (
    <Provider>
      {" "}
      <Analytics />
      <ProjectRoutes /> {showScrollToTop && <ScrollToTopButton />}
    </Provider>
  );
}
export default App;
