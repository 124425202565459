import React, { useRef, useEffect, useState, Suspense } from "react";
import cookie from "../../assets/cookie.svg";
import subscribeimage from "../../assets/img/popup.jpg";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TopSection from "./sections/topSection";
import ProcessSection from "./sections/processSection";

const LocationSection = React.lazy(() => import("./sections/locationSection"));
const DestinationSection = React.lazy(() =>
  import("./sections/destinationSection")
);
const MobileAppSection = React.lazy(() =>
  import("./sections/mobileAppSection")
);
const MemberSection = React.lazy(() => import("./sections/memberSection"));
const OffersSection = React.lazy(() => import("./sections/offersSection"));
const TestimonialsSection = React.lazy(() =>
  import("./sections/testimonialsSection")
);
const ScuttlrSection = React.lazy(() => import("./sections/scuttlrSection"));

export default function HomePage() {
  const [showModal, setShowModal] = useState(true);
  const [visible, setVisible] = useState(false);
  // const [isMobile, setIsMobile] = useState(true);
  // const [email, setEmail] = useState("");
  const location = useLocation();
  const [cookieContent, setCookieContent] = useState(false);

  useEffect(() => {
    document.title = "Checkins.ai";
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const queryValue = urlParams.get("query");

    if (queryValue) {
      localStorage.setItem("storedValue", queryValue);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedCurrency", "USD");
    let isScrolling = false;
    let scrollTimeout;

    const handleScroll = () => {
      clearTimeout(scrollTimeout);
      isScrolling = true;

      scrollTimeout = setTimeout(() => {
        isScrolling = false;
      }, 100); // Adjust the timeout duration as needed
    };
    window.addEventListener("scroll", handleScroll);
    setTimeout(() => {
      const token = localStorage.getItem("token");
      const lastShown = localStorage.getItem("lastShown");
      const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000; // in milliseconds
      const THREE_YEARS = 30 * 24 * 60 * 60 * 1000; // in milliseconds

      if (token) {
        setShowModal(false);
        setVisible(false);
      } else if (
        !lastShown ||
        new Date() - new Date(lastShown) > TWENTY_FOUR_HOURS
      ) {
        setShowModal(true);
        setVisible(true);
        localStorage.setItem("lastShown", new Date());
        setTimeout(() => {
          if (!isScrolling) {
            setCookieContent(true);
          }
        }, THREE_YEARS);
      }
    }, 15000);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    checkCookie(); //check if cookie exists
  }, []);

  const checkCookie = () => {
    const cookieValue = Cookies.get("userCookieId");
    const userId = uuidv4();

    if (cookieValue) {
      console.log("cookie already exists");
    } else {
      Cookies.set("userCookieId", userId, { expires: 5000000 });
    }
  };
  return (
    <div className="">
      <TopSection />
      <Suspense fallback={<div></div>}>
        <LocationSection />
        {/* <ScuttlrSection /> */}
        <OffersSection />
        <DestinationSection />
        <TestimonialsSection />
        <MobileAppSection />
      </Suspense>
      {cookieContent ? (
        <div className="px-4 w-full pb-4 z-50 fixed bottom-0">
          {/* {cookieContent && (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="Travel Taste Buds"
          style={{ background: "#002248" }}
          buttonStyle={{
            background: "#1893F8",
            color: "#fff",
            fontSize: "13px",
          }}
          expires={150}
        >
          At Checkins.ai, we believe in making your hotel booking experience as
          delightful as possible. To achieve this, we use a special ingredient
          called cookies. These virtual treats are small pieces of data that
          help us personalize your journey, improve website performance, and
          enhance your overall browsing experience.{" "}
        </CookieConsent>
      )} */}
          <div className="bg-[#002248] rounded-lg text-white px-6 py-4 ">
            <div className="flex flex-row gap-3 mb-4">
              {" "}
              <span>But first , cookies</span>
              <LazyLoadImage src={cookie} className="h-5" alt="cookie" />
            </div>
            <span>
              At Checkins.ai, we believe in making your hotel booking experience
              as delightful as possible. To achieve this, we use a special
              ingredient called cookies. These virtual treats are small pieces
              of data that help us personalize your journey, improve website
              performance, and enhance your overall browsing experience.{" "}
            </span>
            <button
              onClick={() => setCookieContent(false)}
              className="bg-[#1893F8] rounded-md hover:underline px-2 py-2"
            >
              Accept All
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
